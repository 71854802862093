import axios from 'axios';


export default axios.create({
	baseURL: 'https://dbsport20245.otnielpangkung.com/',
});
// export default axios.create({
// 	baseURL: 'http://localhost:3001',
// });

