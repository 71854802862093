<template>
  <div class="home">
    <div class="inRow">
      <!-- <p class="title">My Sport</p> -->
      <img src="../assets/2.png" alt="">
    </div>
    <div class="filterRow inRow" >
      <div class="inRow">
        <button class="button" type="button" @click="showAdd()">Add Sport</button>
      </div>
      <div class="inRow">
        <button class="button" type="button" @click="showAddMutation()">Add Mutation</button>
      </div>
    </div>
    <div class="filterRow inRow" >
      <div class="inRow mr-2">
        <b-select v-model="month"  @change="this.getRekap">
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </b-select>
      </div>
      <div class="inRow ml-2">
        <b-select v-model="year"  @change="this.getRekap">
          <option value="2023">2023</option>
          <option value="2024">2024</option>
          <option value="2025">2025</option>
        </b-select>
      </div>


    </div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Sport Name</th>
          <th>Target</th>
          <th>Total</th>
          <th v-for="(e, index) in totalDateArray" :key="index">{{ e }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="e in sports" :key="e.id">
          <td>{{ e.name }}</td>
          <td class="text-right" @click="showTarget(e)"> {{ formatUang(filterTarget(e)) }}</td>
          <td class="text-right">{{ formatUang(filterRekap(e.id,'total')) }} </td>
          <td class="text-right" v-for="(x, index) in totalDateArray" :key="index">{{ formatUang(filterRekap(e.id,x)) }} </td>
        </tr>
      </tbody>
    </table>

    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Sport Name</th>
          <th>Tanggal</th>
          <th>Jam</th>
          <th>Detail</th>
          <th>Jumlah</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="e in mutations" :key="e.id">
          <!-- <td>{{ e }}</td> -->
          <td>{{ e.Sport.name }}</td>
          <td>{{ e.date }}</td>
          <td>{{ e.time }}</td>
          <td>{{ e.detail }}</td>
          <td>{{ e.volume }}</td>
          <td>
            <button class="button" id="deleteButton" type="button" @click="deleteMutation(e.id)">
              Hapus
            </button>
            </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal -->
    <div class="modal" id="sportModal" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">New Sport</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times; </span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="isEdit ? editData() : addData()">

              <div class="inRow">
                <div class="inCol">
                  <p id="titleModal">Sport Name</p>
                  <b-input v-model="sportname" type="text" required> </b-input>
                </div>

            
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Tambah</button>
            </div>
          </form>
          </div>
        </div>
      <!-- </div> -->
    </div>

    <!-- Modal -->
    <div class="modal" id="targetModal" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">New Sport</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times; </span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent=" addTarget()">

              <div class="inRow">
                <div class="inCol">
                  <p id="titleModal">Target</p>
                  <b-input v-model="volumeTarget" type="number" required> </b-input>
                </div>

            
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Tambah</button>
            </div>
          </form>
          </div>
        </div>
      <!-- </div> -->
    </div>

    <!-- Mutation -->
    <div class="modal" id="mutation" tabindex="-1">
      <!-- <div class="modal-dialog"> -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">New Sport</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >&times; </span>
            </button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="isEdit ? editData() : addMutation()">

              <div class="inRow">
                <div class="inCol">
                  <p id="titleModal">Sport Name</p>
                  <b-select v-model="SportId" required>
                    <option v-for="e in sports" :key="e.id" :value="e.id">{{ e.name }}</option>
                  </b-select>
                </div>
                <div class="inCol">
                  <p id="titleModal">volume</p>
                  <b-input class="text-right" v-model="volume" type="number" required> </b-input>
                </div>
                <div class="inCol">
                  <p id="titleModal">Detail</p>
                  <b-input class="text-right" v-model="detail" type="text" required> </b-input>
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-primary">Tambah</button>
            </div>
          </form>
          </div>
        </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "../../axios";
import Swal from "sweetalert2";
import moment from 'moment'


export default {
  name: 'HomeView',
  data() {
    return {
      totalDate: 31,
      isEdit: false,
      sports: [],
      mutations: [],
      rekaps: [],
      targets : [],
      sportname: '',
      SportId: 0,
      detail: '',
      volume: 0,
      currentPage: 1,
      perPage: 30,
      month: '',
      year: '',
      volumeTarget: 0,
      SportId: 0,
    }
  },
  computed: {
    totalDateArray() {
      // Buat array dengan panjang yang sama dengan totalDate
      return Array.from({ length: this.totalDate }, (_, index) => index + 1);
    }
  },
  created() {
    this.getThisTimne()
    this.getSport()
    this.getMutations()
    this.getRekap()
    this.getTarget()
  },
  methods: {
    showAdd() {
      this.isEdit = false
      $('#sportModal').modal('show')
    },
    showAddMutation() {
      this.isEdit = false
      $('#mutation').modal('show')
    },
    async getThisTimne() {
      
      try {
        let month= moment(new Date()).format('MM')
        let year= moment(new Date()).format('YYYY')
        this.month = month
        this.year = year
      } catch(err) {
        console.log(err);
      }
    },
    showTarget(e) {
      $('#targetModal').modal('show')
      this.SportId = e.id
    },
    async getSport() {
      
      try {
        
        let {data} = await axios.get("/sport")
        this.sports = data
      } catch(err) {
        console.log(err);
      }
    },
    async getMutations() {
      // console.log("mausk mutation");
      try {
        
        let {data} = await axios({
          url: "/mutation",
          method: 'get',
          params: {
            page: this.currentPage,
            limit: this.perPage,
          }
        })
        console.log("---",  data, "---data");
        this.mutations = data.data2.result
        console.log(this.mutations);
        let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
				this.totalPage = totalPage
      } catch(err) {
        console.log(err);
      }
    },
    async getRekap() {
      
      try {
        
        let {data} = await axios({
          url: "/rekap",
          method: 'get',
          params: {
            month: this.month,
            year: this.year,
          }
        })
        // console.log(data, "---data");
        this.rekaps = data
      } catch(err) {
        console.log(err);
      }
    },
    
    filterRekap(SportId, date) {
      let result = 0
      if(date == 'total') {
        this.rekaps.map(e => {
          if(e.SportId == SportId) result+= +e.volume
        })
      } else {
        result = this.rekaps.find(e => e.SportId == SportId && date == moment(e.date).format("DD"))     
        if(result) result = result.volume
        else result = 0
      }

      return result
    },
    async getTarget() {
       
      try {
        
        let {data} = await axios({
          url: "/target",
          method: 'get',
          params: {
            month: this.month,
            year: this.year,
          }
        })
        // console.log(data, "---data");
        this.targets = data
      } catch(err) {
        console.log(err);
      }
    },
    filterTarget(sport) {
      let result = 0

      if(this.targets.length > 0) {
        result = this.targets.find(e => e.SportId == sport.id)?.volume     
        if(!result) result = 0
      } 

      return result
    },
    async addData() {

      try {
        let data = await axios({
          url: '/sport',
          method: 'post',
          data: {
            name: this.sportname
          }
        })
        this.getSport()
        $('#sportModal').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async addMutation() {

      try {
        let data = await axios({
          url: '/',
          method: 'post',
          data: {
            SportId: this.SportId,
            volume: this.volume,
            detail: this.detail,
          }
        })
        this.getMutations()
        this.getRekap()
        $('#mutation').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async addTarget() {

      try {
        let data = await axios({
          url: '/target',
          method: 'post',
          data: {
            SportId: this.SportId,
            year: this.year,
            month: this.month,
            volume: this.volumeTarget,
          }
        })
        this.getTarget()
        $('#targetModal').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },
    async deleteMutation(id) {

      try {
        let data = await axios({
          url: `/${id}`,
          method: 'delete',
        })
        this.getMutations()
        $('#mutation').modal('hide')
        Swal.fire('Berhasil', 'Telah diproses', 'success');
      } catch(err) {
        console.log(err);
        Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
      }
    },

    formatUang(data) {
      // console.log(data);
			let uang = 0;
      if(data) {
        uang = ""
        data = data.toString();
        for (let i = 0; i < data.length; i++) {
          if ((data.length - i) % 3 == 0 && i !== 0) {
          uang += `.${data[i]}`;
          } else {
          uang += data[i];
          }
        }
      }
      return uang
    },
    
  }
}
</script>

<style>
  .home {
    padding: 10px;
  }
  p.title {
    margin: 0;
    font-size: 32px;
    font-weight: 800;
  }
  .table thead th {
    font-size: 15px;
    padding: 1px !important;
    /* border-radius: 20px; */
    /* background-color: aqua; */
  }
  .table tbody tr td{
    font-size: 12px ;
    padding: 1px;
    /* background-color: aqua; */
  }
  .inRow {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 0;
    /* justify-content: center; */
  }
  .inCol {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .button {
    margin: 10px;
    background-color: #42B27C;
    color: #F6F4EB;
    padding: 10px 20px;
    border-radius: 15px;
    border: none;
  }

  #deleteButton {
    margin: 0;
    padding: 2px 6px;
  }
  .filterRow {
    padding: 10px 20px;
    
  }

  b-select {
    /* margin-bottom: 0px; */
    margin: 10px;

  }

  img {
    max-width: 200px;
    margin: 0;
  }

</style>